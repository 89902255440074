<template>
  <div>
    <b-row v-if="reportData && reportData.statistics">
      <b-col cols="6" xl="3" md="3">
        <statistic-card-horizontal
          icon="BriefcaseIcon"
          :statistic="reportData.statistics.total_jobs.toLocaleString()"
          statistic-title="Total Jobs"
        />
      </b-col>
      <b-col cols="6" xl="3" md="3">
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="reportData.statistics.total_applicants.toLocaleString()"
          statistic-title="Total Applicants"
        />
      </b-col>
      <b-col cols="6" xl="3" md="3">
        <statistic-card-horizontal
          color="info"
          icon="ClipboardIcon"
          :statistic="reportData.statistics.total_applications.toLocaleString()"
          statistic-title="Total Applications"
        />
      </b-col>
      <b-col cols="6" xl="3" md="3">
        <statistic-card-horizontal
          color="warning"
          icon="CalendarIcon"
          :statistic="reportData.statistics.all_interviews_completed"
          statistic-title="Scheduled Interview"
        />
      </b-col>
    </b-row>

    <b-row>
      <!-- NumberOfApplicant -->
      <b-col
        v-if="showComponents.includes('NumberOfApplicantChart')"
        cols="12" xl="6" md="12"
      >
        <remove-component-button
          v-if="isEditReport"
          @click="removeComponent('NumberOfApplicantChart')"
        />
        <number-of-applicant-chart :which-page="'pre-event'" :report-data="reportData" />
      </b-col>

      <!-- PopularCompany -->
      <b-col
        v-if="showComponents.includes('PopularCompany')"
        cols="12" xl="6" md="6"
      >
        <remove-component-button
          v-if="isEditReport"
          @click="removeComponent('PopularCompany')"
        />
        <popular-company
          :title="`Forecasted Popular Companies`"
          :data="reportData && reportData.popular_companies"
          :which-page="'pre-event'"
        />
      </b-col>

      <!-- GenderChart -->
      <b-col
        v-if="showComponents.includes('GenderChart')"
        cols="12" xl="3" md="6"
      >
        <remove-component-button
          v-if="isEditReport"
          @click="removeComponent('GenderChart')"
        />
        <gender-chart :report-data="reportData" />
      </b-col>

      <!-- CitizenshipChart -->
      <b-col
        v-if="showComponents.includes('CitizenshipChart')"
        cols="12" xl="3" md="6"
      >
        <remove-component-button
          v-if="isEditReport"
          @click="removeComponent('CitizenshipChart')"
        />
        <citizenship-chart :report-data="reportData" />
      </b-col>

      <!-- QualificationLevelList -->
      <b-col
        v-if="showComponents.includes('QualificationLevelList')"
        cols="12" xl="6" md="6"
      >
        <remove-component-button
          v-if="isEditReport"
          @click="removeComponent('QualificationLevelList')"
        />
        <qualification-level-list :report-data="reportData" />
      </b-col>

      <!-- EmploymentStatusChart -->
      <b-col
        v-if="showComponents.includes('EmploymentStatusChart')"
        cols="12" xl="3" md="6"
      >
        <remove-component-button
          v-if="isEditReport"
          @click="removeComponent('EmploymentStatusChart')"
        />
        <employment-status-chart :report-data="reportData" />
      </b-col>

      <!-- InterviewTypeChart -->
      <b-col
        v-if="showComponents.includes('InterviewTypeChart')"
        cols="12" xl="3" md="6"
      >
        <remove-component-button
          v-if="isEditReport"
          @click="removeComponent('InterviewTypeChart')"
        />
        <interview-type-chart :report-data="reportData" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

import RemoveComponentButton from './RemoveComponentButton.vue'

import CitizenshipChart from './CitizenshipChart.vue'
import EmploymentStatusChart from './EmploymentStatusChart.vue'
import GenderChart from './GenderChart.vue'
import InterviewTypeChart from './InterviewTypeChart.vue'
import NumberOfApplicantChart from './NumberOfApplicantChart.vue'
import PopularCompany from './PopularCompany.vue'
import QualificationLevelList from './QualificationLevelList.vue'

export default {
  components: {
    StatisticCardHorizontal,

    RemoveComponentButton,

    CitizenshipChart,
    EmploymentStatusChart,
    GenderChart,
    InterviewTypeChart,
    NumberOfApplicantChart,
    PopularCompany,
    QualificationLevelList,
  },
  // props: ['reportData', 'isEditReport'],
  props: {
    isEditReport: {
      type: Boolean,
      default: false,
    },
    reportData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showComponents: [
        'CitizenshipChart',
        'EmploymentStatusChart',
        'GenderChart',
        'InterviewTypeChart',
        'NumberOfApplicantChart',
        'PopularCompany',
        'QualificationLevelList',
      ],
    }
  },

  methods: {
    removeComponent(name) {
      this.showComponents = this.showComponents.filter(
        component => component !== name,
      )
    },
  },
}
</script>

<style></style>
