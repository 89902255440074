<template>
  <b-card v-if="reportData.statistics">
    <b-table
      :fields="fields"
      :items="reportData.statistics.all_feedback"
      striped
      responsive
      class="mb-0"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(interview_date)="data">
        <p>{{ data.value }}</p>
      </template>
    </b-table>
  </b-card>
  <!-- <b-card
    no-body
  >
    <b-card-body class="mx-2">
      <b-row>
        <b-col cols="2"> <h5 class="font-weight-bold"> Applicant </h5>  </b-col>
        <b-col cols="5"> <h5 class="font-weight-bold"> User Feedback </h5> </b-col>
        <b-col cols="5"> <h5 class="font-weight-bold"> Company FeedBack </h5> </b-col>
      </b-row>
      <template
        v-for="i in 5"
      >
        <b-row
          :key="i.id"
          class="my-1 d-flex justify-content-between align-items-center"
        >
          <b-col cols="2">
            <b-media no-body>
              <b-media-aside class="mr-75">
                <b-avatar
                  round
                  size="42"
                  :src="''"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="mb-0">
                  Full Name
                </h6>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="5">
            <div> Lorem ipsum dolor sit. Lorem ipsum dolor sit amet, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cum, alias illo! consectetur adipisicing elit. Sunt ipsam et quos iusto! </div>
          </b-col>
          <b-col cols="5">
            <div> Lorem ipsum dolor sit amet consectetur. Lorem, ipsum dolor. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ipsam et quos iusto! </div>
          </b-col>
        </b-row>
      </template>
    </b-card-body>
  </b-card> -->
</template>

<script>
import { format } from 'date-fns'
import CellRendererApplicant from './cell-renderer/CellRendererApplicant.vue'

export default {
  components: {
    CellRendererApplicant,
  },
  props: ['reportData'],
  data() {
    return {
      fields: [
        { key: 'interview_date', sortable: true },
        { key: 'interview_time', sortable: true },
        { key: 'fullname', sortable: false },
        { key: 'company_name', sortable: false },
        { key: 'applicant_feedback', sortable: false },
        { key: 'company_feedback', sortable: false },
      ],

      interviewData: [],
    }
  },
  computed: {
    interviewDataDisplay() {
      const result = this.reportData.statistics.all_feedback

      return result
    },
  },
  methods: {
    formatDatetime(oldDate) {
      return format(oldDate, 'LLLL yyyy')
    },
  },
}
</script>

<style></style>
