<template>
  <b-avatar
    variant="danger"
    size="30"
  >
    <feather-icon
      icon="TrashIcon"
      size="20"
      @click="$emit('click')"
    />
  </b-avatar>
</template>

<script>
export default {

}
</script>

<style>

</style>