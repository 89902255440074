<template>
  <b-card
    no-body
    :style="`height: ${componentOptions.cardHeight ? componentOptions.cardHeight : '60vh'};`"
  >
    <b-card-header>
      <b-card-title>Job Applications</b-card-title>
    </b-card-header>

    <div
      :style="`height: 80%; overflow-y: scroll; overflow-x: hidden;`"
      class="mx-1"
    >
      <b-overlay :show="isLoading">
        <b-row
          v-for="key in Object.keys(itemList)"
          :key="key.id"
          class="mb-2 px-1"
        >
          <b-col cols="12">
            <h6>{{ key }}</h6>
          </b-col>
          <b-col cols="12">
            <b-progress
              variant="success"
              :value="itemList[key]"
              :max="totalItems"
              show-value
              animated
            />
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </b-card>
</template>

<script>
import { sum } from 'lodash'

export default {
  props: ['componentOptions', 'reportData', 'section'],
  data() {
    return {
      isLoading: false,
      itemList: [],
      totalItems: 0,
    }
  },

  watch: {
    reportData(newVal, oldVal) {
      this.updateData(newVal)
    },
  },

  mounted() {
    if (Object.keys(this.reportData).length > 0) {
      this.updateData(this.reportData)
    }
  },
  methods: {
    updateData(newVal) {
      // this.qualificationLevels = newVal.qualifications
      this.itemList = newVal.statistics.charts[this.section].job_and_application_chart

      // console.log(this.qualificationLevels)
      if (this.itemList) {
        // this.totalQualifications = sum(
        //   this.qualificationLevels.map(qualification => qualification.value),
        // )
        for (const key in this.itemList) {
          this.totalItems += this.itemList[key]
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.qualiticationLevel-container {
  height: 120vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
