<template>
  <div class="d-flex justify-content-start">
    <b-avatar :src="params.data.image" class="flex-shrink-0 mr-2" size="40px" @click="$router.push(url)" />
    <router-link :to="url" @click.stop.prevent class="text-inherit text-dark hover:text-primary">
      {{ params.value }}
    </router-link>
  </div>
</template>

<script>
import { copyFileSync } from 'fs'
import { BAvatar, BImg } from 'bootstrap-vue'

export default {
  name: 'CellRendererLink',
  components: {
    BAvatar,
    BImg
  },
  computed: {
    url () {
      // return `/user-manager/${ params.data.id }`
      return `/analytics/platform/${ 1 } `
      // return '/apps/user/user-view/268'

      // Below line will be for actual product
      // Currently it's commented due to demo purpose - Above url is for demo purpose
      // return "/apps/user/user-view/" + this.params.data.id
    }
  },

  mounted() {
    // console.log(this.params)
  }
}
</script>
