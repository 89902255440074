<template>
  <div>
    <b-col cols="12">
      <b-card class="p-1">
        <b-row class="w-100 m-0 p-0">
          <b-col
            col
            cols="12"
            xl="3"
            md="3"
            class=""
          >
            <b-button
              variant="primary"
              icon-pack="feather"
              icon="icon-arrow-left"
              :class="{'w-100': isMobile}"
              @click="$router.push({ name: 'platform-analytics' })"
            >Go Back</b-button>
          </b-col>
          <b-col
            col
            cols="12"
            xl="9"
            md="9"
            class="text-center mt-1 mt-xl-0 mt-md-0 w-100"
          >
            <!-- <h3>Reports Name</h3> -->
            <h3 class="m-0 p-0">
              {{ $route.params.report_name.split('-')[0] }}
            </h3>
          </b-col>

          <b-col
            cols="3"
            class="text-right"
          >
            <!-- <b-button
              variant="warning"
              icon-pack="feather"
              icon="icon-edit-2"
              @click="isEditReport = !isEditReport"
            >Edit Report</b-button> -->
            <!-- <b-button
              variant="dark"
              icon-pack="feather"
              class="shadow-lg mx-1"
            >Download Report</b-button> -->
          </b-col>
        </b-row>
      </b-card>

      <b-tabs
        lazy
        pills
        justified
        align="center"
        class="tab-action-btn-fill-container"
      >
        <!-- <b-tab
          lazy
        >
          <template #title>
            <span class="">Pre Event</span>
          </template>

          <pre-event
            :is-edit-report="isEditReport"
            :report-data="reportData"
          />
        </b-tab>

        <b-tab lazy>
          <template #title>
            <span class="">During Event</span>
          </template>

          <during-event
            :is-edit-report="isEditReport"
            :report-data="reportData"
          />
        </b-tab>

        <b-tab lazy>
          <template #title>
            <span class="">Post Event</span>
          </template>

          <post-event :report-data="reportData" />
        </b-tab> -->

        <b-tab lazy>
          <template #title>
            <span class="">Summary</span>
          </template>
          <b-overlay
            variant="white"
            :show="isLoading"
            rounded="lg"
          >
            <platform-analytics-summary :report-data="reportData" />
          </b-overlay>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { onResizeMixins } from '@/components/mixins/onResizeMixins'

// tabs
import PreEvent from './components/PreEvent.vue'
import DuringEvent from './components/DuringEvent.vue'
import PostEvent from './components/PostEvent.vue'
import PlatformAnalyticsSummary from './components/PlatformAnalyticsSummary.vue'

export default {
  components: {
    // tabs
    PreEvent,
    DuringEvent,
    PostEvent,
    PlatformAnalyticsSummary,
  },
  mixins: [onResizeMixins],
  data() {
    return {
      isEditReport: false,
      isLoading: false,
      reportData: {},
    }
  },
  watch: {
  },
  created() {
    this.getData()
  },
  mounted() {
    this.reportData = {
      statistics: {
        total_jobs: 0,
        total_applicants: 0,
        total_applications: 0,
        all_interviews_completed: 0,
        number_of_users_account: 0,
        number_of_employers_account: 0,
        charts: {
          job_seeker: {
            gender: {
              Male: 0,
              Female: 0,
            },
            employment_status: {
              'Self-employed': 0,
              Unemployed: 0,
              Employed: 0,
            },
            users_activity_per_month: {

            },
          },
          employer: {
            users_activity_per_month: {

            },
          },
        },
      },
    }
  },
  methods: {
    removeChart(uen) {
      this.charts = this.charts.filter(chart => {
        if (chart.uen !== uen) return chart
      })
    },

    getData() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true

      this.$http
        .get('/api/general-analytics')
        .then(response => {
          const output = response.data
          const { success, analytics } = output
          console.log(analytics)
          this.reportData = {
            statistics: {
              number_of_users_account: analytics.total_applicants,
              number_of_employers_account: analytics.total_employers,
              charts: {
                job_seeker: {
                  gender: {
                    Male: analytics.males_count,
                    Female: analytics.females_count,
                    Unspecified: analytics.gender_unspecified_count,
                  },
                  employment_status: {
                    Employed: analytics.employed_count,
                    'Self-employed': analytics.self_employed_count,
                    Unemployed: analytics.unemployed_count,
                    Unspecified: analytics.employment_status_unspecified_count,
                  },
                  users_activity_per_month: analytics.jobseeker_chart,
                  age_demographic: analytics.age_demographic_chart,
                  // job_applications_chart: analytics.job_applications_chart,
                  highest_qualification_count: analytics.highest_qualification_count,
                  job_and_application_chart: analytics.job_and_application_chart,
                },
                employer: {
                  users_activity_per_month: analytics.employer_chart,
                  active_job_chart: analytics.active_job_chart,
                  multiline_application_and_job_chart: analytics.multiline_application_and_job_chart,
                },
              },

              tracking: analytics.tracking,
            },
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Failed',
                icon: 'CheckCircleIcon',
                text: 'Could not retrieve the statistics. Please try again later.',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/variables.scss';

.back-button {
  background-color: $sidebar-dark-bg;
}
</style>
