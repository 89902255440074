<template>
  <div>
    <h4>Status</h4>
    <b-row v-if="reportData.statistics">
      <b-col>
        <statistic-card-vertical
          color="success"
          icon="CheckIcon"
          :statistic="reportData.statistics.Hired.toLocaleString()"
          statistic-title="Hired"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="info"
          icon="ListIcon"
          :statistic="reportData.statistics.Shortlisted.toLocaleString()"
          statistic-title="Shortlisted "
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="danger"
          icon="XIcon"
          :statistic="reportData.statistics.Rejected.toLocaleString()"
          statistic-title="Unsuccessful"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="warning"
          icon="ClockIcon"
          :statistic="reportData.statistics['Kept In View'].toLocaleString()"
          statistic-title="Pending"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="dark"
          icon="AlertTriangleIcon"
          :statistic="reportData.statistics['Unknown'].toLocaleString()"
          statistic-title="Unknown"
        />
      </b-col>
    </b-row>

    <!-- Feedback -->
    <h4>Feedback</h4>
    <feedback-table :report-data="reportData" />
  </div>
</template>

<script>
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import FeedbackTable from './feedback-table/FeedbackTable.vue'

export default {
  components: {
    FeedbackTable,

    StatisticCardVertical,
  },
  props: {
    isEditReport: {
      type: Boolean,
      default: false,
    },
    reportData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style></style>
