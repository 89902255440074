<template>
  <div>
    <!-- <h2>Status</h2> -->
    <!-- <b-row v-if="reportData.statistics"> -->
    <b-row v-if="false">
      <b-col>
        <statistic-card-vertical
          color="success"
          icon="CheckIcon"
          :statistic="reportData.statistics.Hired"
          statistic-title="Hired"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="warning"
          icon="ListIcon"
          :statistic="reportData.statistics.Shortlisted"
          statistic-title="Shortlisted "
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="danger"
          icon="XIcon"
          :statistic="reportData.statistics.Rejected"
          statistic-title="Unsuccessful"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="warning"
          icon="ClockIcon"
          :statistic="reportData.statistics['Kept In View']"
          statistic-title="Pending"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="danger"
          icon="AlertTriangleIcon"
          :statistic="reportData.statistics.Unknown"
          statistic-title="Unknown"
        />
      </b-col>
    </b-row>
    <hr>

    <div class="d-flex justify-content-start">
      <h3 class="mb-1">
        Job Seeker
      </h3>
    </div>

    <b-row v-if="false">
      <b-col>
        <statistic-card-horizontal
          icon="BriefcaseIcon"
          :statistic="reportData.statistics.total_jobs"
          statistic-title="Total Jobs"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="reportData.statistics.total_applicants"
          statistic-title="Total Applicants"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          icon="CalendarIcon"
          :statistic="reportData.statistics.total_applications"
          statistic-title="Scheduled Interview"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          variant="success"
          icon="CheckIcon"
          :statistic="reportData.statistics.all_interviews_completed"
          statistic-title="Interview Completed"
        />
      </b-col>
    </b-row>

    <b-row>
      <!-- <b-col
        cols="12" xl="6" md="12"
      >
        <number-of-applicant-chart
          :report-data="reportData"
          :which-page="'summary'"
        />
      </b-col> -->

      <!-- <b-col
        cols="12" xl="6" md="6"
      >
        <popular-company :data="reportData.popular_companies" />
      </b-col> -->

      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="reportData.statistics.number_of_users_account"
          statistic-title="Number of Job Seekers Account"
        />
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <number-of-applicant-chart
            :report-data="reportData"
            :which-page="'summary'"
            title="User Login Activity (Based on login time) - 30 days"
            section="job_seeker"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <age-demographic-chart
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Age demographic"
            section="job_seeker"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12"
        xl="4"
        md="6"
      >
        <gender-chart
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <citizenship-chart :report-data="reportData" />
      </b-col> -->

      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <qualification-level-list :report-data="reportData" />
      </b-col> -->

      <b-col
        cols="12"
        xl="4"
        md="6"
      >
        <employment-status-chart
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <b-col
        cols="12"
        xl="4"
        md="6"
      >
        <qualification-level-list
          :component-options="{
            cardHeight: '60vh'
          }"
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <b-col
        v-if="false"
        cols="12"
        xl="4"
        md="6"
      >
        <job-and-application-list
          :component-options="{
            cardHeight: '60vh'
          }"
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <b-col
        v-if="false"
        cols="12"
        xl="6"
        md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <ChartComponent
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Job Applications - 30 days"
            section="job_seeker"
            data-name="job_applications_chart"
          />
        </b-col>
      </b-col>
      <template v-if="false">
        <b-col
          cols="12"
          xl="6"
          md="6"
        >
          <statistic-card-horizontal
            icon="LayoutIcon"
            :statistic="Object.values(reportData.statistics.tracking.page_visit_unique).reduce((accumulator, currentValue) => accumulator + currentValue, 0)"
            statistic-title="Total of Page Visit (Unique)"
          />
        </b-col>
        <b-col
          cols="12"
          xl="6"
          md="6"
        >
          <statistic-card-horizontal
            icon="LayoutIcon"
            :statistic="Object.values(reportData.statistics.tracking.page_visit_absolute).reduce((accumulator, currentValue) => accumulator + currentValue, 0)"
            statistic-title="Total of Page Visit (Absolute)"
          />
        </b-col>
        <b-col
          cols="12"
          xl="6"
          md="6"
        >
          <custom-analytic-card
            title="Page Visit (Unique)"
          >
            <table class="custom-table">
              <thead>
                <tr>
                  <td>Page</td>
                  <td>Count</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="pageVisit, key in reportData.statistics.tracking.page_visit_unique"
                  :key="key.key"
                >
                  <td>{{ key }}</td>
                  <td class="font-weight-bolder">
                    {{ pageVisit }}
                  </td>
                </tr>
              </tbody>
            </table>
          </custom-analytic-card>
        </b-col>
        <b-col
          cols="12"
          xl="6"
          md="6"
        >
          <custom-analytic-card
            title="Page Visit (Absolute)"
          >
            <table class="custom-table">
              <thead>
                <tr>
                  <td>Page</td>
                  <td>Count</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="pageVisit, key in reportData.statistics.tracking.page_visit_absolute"
                  :key="key.key"
                >
                  <td>{{ key }}</td>
                  <td class="font-weight-bolder">
                    {{ pageVisit }}
                  </td>
                </tr>
              </tbody>
            </table>
          </custom-analytic-card>
        </b-col>
      </template>

      <!-- Page Visits -->
      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <custom-analytic-card
          title="Page Visits"
        >
          <table class="custom-table">
            <thead>
              <tr>
                <td>Page</td>
                <td>Unique</td>
                <td>Absolute</td>
              </tr>
            </thead>
            <tbody v-if="reportData.statistics.tracking">

              <tr
                v-for="pageVisit, key in reportData.statistics.tracking.page_visit_unique_and_absolute"
                :key="key.key"
                class=""
              >
                <td>{{ key }}</td>
                <td class="font-weight-bolder text-left">
                  {{ $thousandSeparated(pageVisit.unique) }}
                </td>
                <td class="font-weight-bolder text-left">
                  {{ $thousandSeparated(pageVisit.absolute) }}
                </td>
              </tr>

              <tr class="row-summary">
                <td><b>TOTAL</b></td>
                <td class="text-left">
                  {{
                    $thousandSeparated(
                      Object.values(reportData.statistics.tracking.page_visit_unique_and_absolute).map(item => item.unique).reduce((sum, count) => sum + count, 0)
                    )
                  }}
                </td>
                <td class="text-left">
                  {{
                    $thousandSeparated(
                      Object.values(reportData.statistics.tracking.page_visit_unique_and_absolute).map(item => item.absolute).reduce((sum, count) => sum + count, 0)
                    )
                  }}
                </td>
              </tr>

            </tbody>

          </table>
        </custom-analytic-card>
      </b-col>

      <!-- TRACKING ANALYTICS -->
      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <custom-analytic-card
          title="Tracking Analytics"
        >
          <table class="custom-table">
            <tbody v-if="reportData.statistics.tracking">
              <tr>
                <td>Average Session Duration</td>
                <td class="font-weight-bolder text-right">
                  {{ reportData.statistics.tracking.session_duration_average }}
                </td>
              </tr>
              <tr>
                <td>Total 'Apply Now' button clicked</td>
                <td class="font-weight-bolder text-right">
                  {{ $thousandSeparated(reportData.statistics.tracking.form_abandon_rate.total_form_count) }}
                </td>
              </tr>
              <tr>
                <td>Submitted Application Form</td>
                <td
                  class="font-weight-bolder"
                >
                  <div class="d-flex justify-content-between">
                    <div>
                      ( {{ parseFloat(reportData.statistics.tracking.form_abandon_rate.form_submit_percentage).toFixed(2) }}% )
                    </div>
                    <div>
                      {{ $thousandSeparated(reportData.statistics.tracking.form_abandon_rate.form_submit_count) }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Abandoned Application Form</td>
                <td
                  class="font-weight-bolder"
                >
                  <div class="d-flex justify-content-between">
                    <div>
                      ( {{ parseFloat(reportData.statistics.tracking.form_abandon_rate.form_not_submit_percentage).toFixed(2) }}% )
                    </div>
                    <div>
                      {{ $thousandSeparated(reportData.statistics.tracking.form_abandon_rate.form_not_submit_count) }}
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td>Bounce Rate</td>
                <td
                  class="font-weight-bolder"
                >
                  <div class="d-flex justify-content-between">
                    <div>
                      ( {{ parseFloat(reportData.statistics.tracking.bounce_rate.percentage).toFixed(2) }}% )
                    </div>
                    <div>
                      {{ $thousandSeparated(reportData.statistics.tracking.bounce_rate.number) }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </custom-analytic-card>
      </b-col>

      <!-- Source of Visitor (UTM) -->
      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <custom-analytic-card
          title="Source of Visitors (UTM)"
        >
          <table class="custom-table">
            <thead>
              <tr>
                <td>Source</td>
                <td>Medium</td>
                <td>Campaign</td>
                <td>Count</td>
              </tr>
            </thead>

            <tbody v-if="reportData.statistics.tracking">
              <tr
                v-for="utm, key, index in reportData.statistics.tracking.source_of_visitor_utm"
                :key="utm.key"
              >
                <td>{{ utm.utm_source }}</td>
                <td>{{ utm.utm_medium }}</td>
                <td>{{ utm.utm_campaign }}</td>
                <td class="font-weight-bolder">
                  {{ utm.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </custom-analytic-card>
      </b-col>

      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <interview-type-chart :report-data="reportData" />
      </b-col> -->
    </b-row>

    <div class="d-flex justify-content-start">
      <h3 class="mb-1">
        Employer
      </h3>
      <hr class="bg-primary">
    </div>

    <b-row>
      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <gender-chart :report-data="reportData" section="employer"/>
      </b-col>
      <b-col
        cols="12" xl="3" md="6"
      >
        <employment-status-chart :report-data="reportData" section="employer"/>
      </b-col> -->

      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="reportData.statistics.number_of_employers_account"
          statistic-title="Number of Employers Account"
        />
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <number-of-applicant-chart
            :report-data="reportData"
            :which-page="'summary'"
            title="User Login Activity (Based on login time) - 30 days"
            section="employer"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <ChartComponent
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Active Job - 30 days"
            section="employer"
            data-name="active_job_chart"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <MultiDataChartComponent
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Jobs & Applications - 30 days"
            section="employer"
            data-name="multiline_application_and_job_chart"
          />
        </b-col>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

import CitizenshipChart from './CitizenshipChart.vue'
import EmploymentStatusChart from './EmploymentStatusChart.vue'
import GenderChart from './GenderChart.vue'
import InterviewTypeChart from './InterviewTypeChart.vue'
import NumberOfApplicantChart from './NumberOfApplicantChart.vue'
import AgeDemographicChart from './AgeDemographicChart.vue'
import PopularCompany from './PopularCompany.vue'
import QualificationLevelList from './QualificationLevelList.vue'
import JobAndApplicationList from './JobAndApplicationList.vue'
import CustomAnalyticCard from './CustomAnalyticCard.vue'

import ChartComponent from './ChartComponent.vue'
import MultiDataChartComponent from './MultiDataChartComponent.vue'

export default {
  components: {
    StatisticCardHorizontal,
    StatisticCardVertical,

    CitizenshipChart,
    EmploymentStatusChart,
    GenderChart,
    InterviewTypeChart,
    AgeDemographicChart,
    NumberOfApplicantChart,
    PopularCompany,
    QualificationLevelList,
    JobAndApplicationList,
    ChartComponent,
    MultiDataChartComponent,
    CustomAnalyticCard,
  },
  props: ['reportData'],
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.custom-table {
  width: 100%;

  thead {
    line-height: 3.5rem;
    background: $primary;
    color: $white;
  }
  tbody {
    line-height: 3rem;
  }

  tbody tr:nth-child(even) {
    background: lighten($primary, 48%);
  }

  thead td {
    padding-inline: 1rem;
  }

  tbody td {
    padding-inline: 1rem;
  }
  .row-summary {
    background: lighten($primary, 40%);
    font-weight: bolder;
    font-size: 1.5rem;
    line-height: 3.5rem;
  }
}

</style>
