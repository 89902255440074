<template>
  <b-card
    no-body
    style="height: 60vh"
  >
    <b-card-header>
      <b-card-title>Employment Status</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-overlay :show="isLoading">
        <!-- apex chart -->
        <vue-apex-charts
          type="donut"
          height="220"
          class="my-1"
          :options="employmentStatus.chartOptions"
          :series="employmentStatus.series"
        />

        <!-- chart info -->
        <div
          v-for="(data, key, index) in chartInfo"
          :key="key"
          class="d-flex justify-content-between"
          :class="index === Object.keys(chartInfo).length - 1 ? '' : 'mb-1'"
        >
          <div class="series-info d-flex align-items-center">
            <feather-icon
              icon="CircleIcon"
              size="16"
              :class="
                key === 'employed'
                  ? 'text-success'
                  : key === 'self_-_employed'
                    ? 'text-warning'
                    : key === 'unemployed'
                      ? 'text-danger'
                      : 'text-dark'
              "
            />
            <span class="font-weight-bold text-capitalize ml-75">{{
              data.title
            }}</span>
          </div>

          <span>{{ data.value }}</span>
        </div>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: ['reportData', 'section'],
  data() {
    return {
      isLoading: false,
      chartInfo: {
        employed: {
          value: 0,
          title: 'employed',
        },
        'self_-_employed': {
          value: 0,
          title: 'self-employed',
        },
        unemployed: {
          value: 0,
          title: 'unemployed',
        },
        unspecified: {
          value: 0,
          title: 'unspecified',
        },
      },
      employmentStatus: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [
            'Employed',
            'Self-employed',
            'Unemployed',
            'Unspecified',
          ],
          colors: [
            $themeColors.success,
            $themeColors.warning,
            $themeColors.danger,
            $themeColors.dark,
          ],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      },
    }
  },

  watch: {
    reportData(newVal, oldVal) {
     this.updateData(this.reportData)
    },
  },
  mounted() {
    if (Object.keys(this.reportData).length > 0) {
      this.updateData(this.reportData)
    }
  },
  methods: {
    updateData(newVal) {
      const chartData = newVal.statistics.charts[this.section].employment_status
      if (chartData) {
        this.chartInfo = {
          employed: {
            value: chartData.Employed,
            title: 'Employed',
          },
          'self_-_employed': {
            // value: chartData['Unemployed < 6 Months'],
            value: chartData['Self-employed'],
            title: 'Self-employed',
          },
          unemployed: {
            value: chartData.Unemployed,
            title: 'Unemployed',
          },
          unspecified: {
            value: chartData.Unspecified,
            title: 'Unspecified',
          },
        }
        this.employmentStatus = {
          series: [
            // chartData['Unemployed > 6 Months'],
            // chartData['Unemployed < 6 Months'],
            chartData.Employed,
            chartData['Self-employed'],
            chartData.Unemployed,
            chartData.Unspecified,
          ],
          chartOptions: {
            chart: {
              toolbar: {
                show: false,
              },
            },
            labels: [
              'Employed',
              'Self-employed',
              'Unemployed',
              'Unspecified',
            ],
            colors: [
              $themeColors.success,
              $themeColors.warning,
              $themeColors.danger,
              $themeColors.dark,
            ],
            dataLabels: {
              enabled: false,
            },
            legend: { show: false },
            comparedResult: [2, -3, 8],
            stroke: { width: 0 },
          },
        }
      }
    },
  },
}
</script>
